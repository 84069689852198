import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import fetchRss, { mapArticles } from 'helpers/fetch-rss';
import RssFeed from 'components/rss-feed';
import BREAKPOINTS from 'constants/breakpoints';
import withSize from 'hoc/withSize';

function getColumnWidth(screen) {
  if (screen <= BREAKPOINTS.SMALL) return '100%';
  if (screen <= BREAKPOINTS.MEDIUM) return '50%';
  return '33.33%';
}

const News = ({ data, width }) => {
  const [articles, setArticles] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchRss('https://tophotel.news/feed/')
      .then(mapArticles)
      .then(res => setArticles(res.items))
      .finally(() => setIsFetching(false))
      .catch(() => setIsFetching(false));
  }, []);

  useEffect(()=>{
    console.log(articles);
  }, [articles])

  return (
    <Layout>
      <Head pageTitle={data.newsJson.title} />
      <Box>
        <RssFeed
          articles={articles}
          isFetching={isFetching}
          colWidth={getColumnWidth(width)}
        />
      </Box>
    </Layout>
  );
};

News.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
};

export default withSize(News);

export const query = graphql`
  query NewsQuery {
    newsJson {
      title
    }
  }
`;
